.custom-modal {

  @include breakpoint($gt-sm) {
    margin-top: 10rem;
  }

  .modal-content {
    background-color: $modal-bg-color;
  }

  .custom-modal__header {
    display: flex;
    justify-content: center;
    padding: 2rem 2.5rem 1rem 2.5rem;
    position: relative;

    .custom-modal__close {
      content: "";
      width: 17px;
      height: 17px;
      background-image: url("/static/icons/grayClose.svg");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      right: 5px;
      top: 5px;
      opacity: .8;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }

  .custom-modal__title {
    border-bottom: 1px solid $orange;
    text-transform: uppercase;
    padding-bottom: .3rem;
    font-size: 1.7rem;

    @include breakpoint($lt-md) {
      font-size: 1.5rem;
    }
  }

  .custom-modal__body {
    padding: 1rem;
  }
}
