.profile-counter {
  .profile-counter__box {
  }

  .profile-counter__title-box {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
  }

  .profile-counter__title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 0;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .profile-counter__line {
    display: flex;
    justify-content: space-between;
    font-size: 1.8rem;
    margin-bottom: 2rem;

    @include breakpoint($lt-md) {
      font-size: 1.6rem;
    }
  }

  .profile-counter__line-value {
    font-weight: bold;

    &--spacing {
      letter-spacing: .2rem;

      @include breakpoint($lt-md) {
        letter-spacing: .05rem;
      }
    }
  }

  .profile-counter__line-title {
    padding-left: 29px;
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: calc(50% - 13px);
      width: 21px;
      height: 21px;
      background-repeat: no-repeat;
      background-size: cover;

    }

    &--hot {
      &:before {
        background-image: url("/static//icons/HotWhater.svg");
      }
    }

    &--cold {
      &:before {
        background-image: url("/static//icons/ColdWhater.svg");
      }
    }

    &--electric {
      &:before {
        background-image: url("/static//icons/ElectricPower.svg");
      }
    }

    &--heating {
      &:before {
        background-image: url("/static//icons/Heating.svg");
      }
    }
  }

  .profile-counter__delimiter {
    border-bottom: 1px solid $border;
    color: #dadada;
    margin-bottom: 1.5rem;
    padding-bottom: .2rem;
  }

  .profile-counter__box-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 300px;

    .profile-counter__box-empty-icon {
      width: 74px;
      height: 74px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("/static/icons/no-counter.svg");
      margin-bottom: 1rem;
    }

    .profile-counter__box-empty-data-icon {
      width: 74px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("/static/icons/no-counter-data.svg");
      margin-bottom: 1rem;
    }

    .profile-counter__box-empty-text {
      color: $text-gray-little;
      font-size: 2rem;
      text-align: center;
    }
  }

  .profile-counter-skeleton__box {
    .profile-counter-skeleton__title {
      width: 40%;
      margin-bottom: 1.5rem;
      margin-top: .6rem;
    }

    .profile-counter-skeleton__line {
      margin-bottom: 2.4rem;
      margin-top: .5rem;
    }
  }

  .profile-counter__link-box {
    padding-top: 2rem;
    padding-bottom: .5rem;
    text-align: center;

    a {
      color: $orange;
      font-size: 1.6rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
