.mobile-switch__btn-box {
  display: flex;
  margin-bottom: 3rem;

  .btn {
    background-color: $bg-color;
    width: 50%;

    &:focus {
      box-shadow: none;
      margin-left: 0 !important;
    }
    &:active {
      margin-left: 0 !important;
    }
    &:hover {
      box-shadow: none;
      margin-left: 0 !important;
    }

    &.show {
      background-color: $orange;
      color: $white;
    }
  }
}
