.advices-list {

}

.advice-item {
  margin-bottom: 1.5rem;

  .advice-item__box {
    background-color: $white;
    padding: 1.5rem;
  }

  .advice-item__img-box {
    padding-right: 1.5rem;
    height: 150px;

    @include breakpoint($lt-xl) {
      height: 130px;
    }

    @include breakpoint($lt-lg) {
      height: 100px;
    }

    @include breakpoint($lt-md) {
      height: 150px;
      padding-right: 0;
      margin-bottom: 1.5rem;
    }
  }

  .advice-item__img {
    height: 100%;
    width: 100%;
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .advice-item__title {
    display: block;
    font-size: 2.2rem;
    margin-bottom: 1rem;

    @include breakpoint($lt-md) {
      font-size: 1.8rem;
      margin-bottom: .5rem;
    }
  }

  .advice-item__description {
    font-size: 1.6rem;
    margin-bottom: 1rem;

    @include breakpoint($lt-md) {
      font-size: 1.4rem;
      margin-bottom: .5rem;
    }
  }

  .advice-item__date {
    color: $text-gray;
  }

  .advice-item__img-skeleton {
    height: 100%;
    width: 100%;
  }

  .advice-item__title-skeleton {
    height: 2rem;
    margin-bottom: 2rem;
  }

  .advice-item__description-skeleton {
    height: 8px;
  }
}
