.skeleton-line {
  display: inline-block;
  height: 15px;
  width: 100%;
  background: linear-gradient(-90deg, #d1d1d1 0%, #F8F8F8 50%, #d1d1d1 100%);
  background-size: 400% 400%;
  animation: pulse 2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  border-radius: 2px;
  margin-bottom: 3px;
}
