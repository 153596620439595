.service-list {
  .service-list__box {
    background-color: $white;
    padding: 5rem 5rem 3rem 5rem;

    @include breakpoint($lt-md) {
      padding: 5rem 2rem 2rem 2rem;
    }
  }

  .service-list__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .service-list__img-box {
    margin-bottom: 1.4rem;
  }

  .service-list__img {
    width: 84px;
    height: 84px;
    border-radius: 50%;
  }

  .service-list__title {
    font-size: 1.6rem;
  }
}

.service-list__item {
  text-transform: uppercase;
  font-size: 1.6rem;
  border-bottom: 1px solid $border;
  padding: .5rem 0;

  &:last-child {
    border-bottom: none;
  }
}


.service-list__performers-item {
  margin-bottom: 4rem;
  display: flex;
}

.service-list__performers-img-box {
  margin-right: 1rem;
  width: 42px;
  height: 42px;
  position: relative;

  &--discount {
    &:after {
      content: "%";
      position: absolute;
      right: -3px;
      top: -3px;
      width: 22px;
      height: 22px;
      background-color: $orange;
      border-radius: 50%;
      padding: 3px 6px;
      color: $white;
    }
  }
}

.service-list__performers-img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid $border;
}

.service-list__performers-info {
  width: 100%;
  font-size: 1.4rem;
}

.service-list__performers-contacts {
  display: flex;
  justify-content: space-between;
  padding-right: 2rem;
}
