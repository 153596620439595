.profile-info {
  margin-bottom: 3rem;
  .profile-info__sidebar {
    padding-right: 1.5rem;

    @include breakpoint($lt-md) {
      padding-right: 0;
      margin-bottom: 1.5rem;
    }
  }
  .profile-info__box {
    background-color: $white;
    padding: 1.3rem;

    +.profile-info__box {
      margin-top: 1.5rem;
    }
  }

  .profile-info__box-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }

  .profile-info__box-title {
    text-transform: uppercase;
    color: $text-gray;
  }

  .profile-info__box-subtitle {
    text-transform: uppercase;
    color: $text-gray;
    margin-bottom: .5rem;
  }

  dt {
    color: $text-gray;
    font-weight: normal;
    font-size: 1.1rem;
  }

  dd {
    font-weight: bold;
  }

  .profile-info__bill-title {
    text-transform: uppercase;
    color: $text-gray;
    font-size: 1.1rem;
  }

  .profile-info__spinner-box {
    text-align: center;
    padding: 7rem 0 10rem 0;
  }

  .profile-info__bill-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .profile-info__bill-copy {
    color: $orange;
    font-size: 1rem;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .profile-info__email-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: inherit;
    align-items: center;

    span {
      display: block;
    }
  }

  .profile-info__email-copy {
    color: $orange;
    font-size: 1rem;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .profile-info__link-to-order {
    color: $orange;

    &:hover {
      text-decoration: underline;
    }
  }
}
