.map-component {
  width: 100%;

  .map-component__box {
    width: 100%;
    height: 38vw;

    @include breakpoint($lt-md) {
      height: 60vw;
    }
    @include breakpoint($lt-sm) {
      height: 80vw;
    }
  }
}
