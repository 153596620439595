@font-face {
  font-family: 'Circe-Light';
  src:
    url('/fonts/Circe-Light.woff2') format('woff2'),
    url('/fonts/Circe-Light.woff') format('woff');
}
@font-face {
  font-family: 'Circe-Thin';
  src:
    url('/fonts/Circe-Thin.woff2') format("woff2"),
    url('/fonts/Circe-Thin.woff') format("woff");
}
@font-face {
  font-family: 'Circe-Regular';
  src:
    url('/fonts/Circe-Regular.woff2') format("woff2"),
    url('/fonts/Circe-Regular.woff') format("woff");
}

html {
  font-size: 62.5%;
  body {
    font-family: "Circe-Light";
    font-size: 1.3rem;
    background: $bg-color;
  }
}

.regular-font {
  font-family: "Circe-Regular";
}

.container__content {
  margin-top: 3rem;
}

.content-box {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.orange-text {
  color: $orange;
}

.white-bg {
  background-color: $white;
}

.btn {
  font-size: 1.3rem;

  &.btn-lg {
    font-size: 1.6rem;
  }

  &.btn-sm {
    font-size: 1.1rem;
  }
}

.form-control {
  font-size: 1.3rem;

  &.form-control-lg {
    font-size: 1.6rem;
  }

  &.form-control-sm {
    font-size: 1.1rem;
  }
}

.btn-orange {
  background-color: $orange;
  color: $white;

  &:hover {
    color: $white;
    background-color: $orange-hover;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(241,95,33,.25);
  }
}


.btn-orange-outline {
  border: 1px solid $orange;
  color: $orange;

  &:hover {
    color: $white;
    background-color: $orange-hover;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(241,95,33,.25);
  }

  &:disabled {
    &:hover {
      border: 1px solid $orange;
      color: $orange;
      background-color: transparent;
    }
  }
}

a {
  color: $text;

  &:hover {
    text-decoration: none;
    color: $orange;
  }
}

h1 {
  //font-family: 'Circe-Thin';
  font-size: 9rem;
  line-height: 9rem;
}
h2 {
  font-weight: 300;
  font-size: 4rem;
}
h3 {
  font-weight: 300;
  font-size: 2.95rem;
}
h4 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 23px;
}

@include breakpoint($lt-xl) {
  h1 {
    font-size: 7rem;
    font-weight: 900;
    line-height: 7rem;
  }
  h2 {
    font-size: 3rem;
  }
  h3 {
    font-size: 2.3rem;
  }
  h4 {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 23px;
  }
}

@include breakpoint($lt-lg) {
  h1 {
    font-size: 7rem;
    font-weight: 900;
    line-height: 7rem;
  }
  h2 {
    font-size: 3rem;
  }
  h3 {
    font-size: 2.3rem;
  }
  h4 {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 23px;
  }
}

@include breakpoint($lt-md) {
  h1 {
    font-size: 4rem;
    font-weight: 900;
    line-height: 7rem;
  }
  // h2 {
  //   font-size: 3rem;
  // }
  h3 {
    font-size: 2rem;
  }
  // h4 {
  //   font-size: 1.4rem;
  //   font-weight: 700;
  //   letter-spacing: 0.3vw;
  //   line-height: 23px;
  // }
}

@include breakpoint($lt-sm) {
  h1 {
    font-size: 3.6rem;
    line-height: 4.2rem;
  }
  h2 {
    // font-size: 2.4rem;
    // line-height: 3rem;
    font-size: 3.2rem;
    line-height: 4.8rem;
  }
  h3 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

.error-page {
  .error-page__box {
    text-align: center;
    font-size: 1.8rem;

    a {
      text-decoration: underline;
    }

    &--spinner {
      padding-top: 3rem;
    }
  }

}

.select-form-lg__control {
  min-height: 0 !important;

  .select-form-lg__value-container {
    padding: .5rem 1rem;
  }

  .select-form-lg__single-value,
  .select-form-lg__placeholder {
    font-size: 1.6rem;
    white-space: nowrap;
  }

  .select-form-lg__indicator {
    padding: 7px;
  }
  &--menu-is-open,
  &--is-focused {
    border-color: #80bdff !important;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25) !important;
  }

  &:hover {
    border-color: #ced4da !important;
  }
}

.form-complex-selection__select {
  &.is-invalid {
    .select-form-lg__control {
      border-color: #dc3545 !important;

      &--menu-is-open,
      &--is-focused {
        box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25) !important;
      }

    }
  }
}

.spinner-box {
  text-align: center;
  padding: 7rem 0 10rem 0;
}

.breadcrumb-component {
  margin-bottom: 2rem;

  @include breakpoint($lt-md) {
    margin-bottom: 0;
  }
}

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
  padding: .75rem 0;
}

.breadcrumb-item {
  a {
    color: $orange;

    &:hover {
      text-decoration: underline;
    }
  }
}

.breadcrumb-right-btn-box {
  padding-top: .4rem;
  padding-bottom: .4rem;
  display: flex;
  align-items: start;
  justify-content: flex-end;

  @include breakpoint($lt-md) {
    display: block;
    margin-bottom: 1rem;
  }

  .breadcrumb-right-btn {
    display: block;
    color: $orange;
    border: 1px solid $orange;
    padding: .5rem 2rem;
    min-width: 140px;
    text-align: center;

    &:hover {
      color: $white;
      background-color: $orange;
    }
  }
}

.ql-size-huge {
  font-size: 3rem;
}

.ql-size-large {
  font-size: 2.3rem;
}

.ql-size-small {
  font-size: 1.4rem;
}

.subscription-page {
  padding-top: 10rem;

  h2 {
    font-size: 3rem;
  }

  .subscription-page__text-box {
    text-align: center;
    font-size: 1.8rem;
  }

  .subscription-page__link_box {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    img {
      max-width: 130px;
    }

    a + a {
      margin-left: 1rem;
    }
  }
}
