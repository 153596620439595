.news-list {

  .news-item {
    margin-bottom: 30px;

    .news-item__box {
      background-color: $white;
      height: 100%;
    }

    .news-item__img {
      display: block;
      height: 165px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      @include breakpoint($lt-lg) {
        height: 10.5vw;
      }

      @include breakpoint($lt-md) {
        height: 30vw;
      }
    }

    .news-item__content {
      padding: 1.56vw 1.35vw;
    }

    .news-item__title {
      text-transform: uppercase;
      font-size: 1.5rem;
    }

    hr {
      margin-right: 50%;
      border-bottom: 1px solid $orange;
    }

    .news-item__description {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }

    .news-item__date {
      color: $text-gray;
    }

    .news-item__img-skeleton {
      height: 165px;
      border-radius: 0;
      margin-bottom: 0;

      @include breakpoint($lt-lg) {
        height: 10.5vw;
      }

      @include breakpoint($lt-md) {
        height: 30vw;
      }
    }

    .news-item__title-skeleton {
      height: 18px;
      width: 70%;
      margin-bottom: 2rem;
    }

    .news-item__description-skeleton {
      height: 8px;
    }
  }
}
