.dependency-selection {
  margin-bottom: 2.5rem;

  .dependency-selection__container {
    background-color: $orange;
    padding: 1.5rem;
    color: $white;
  }

  .dependency-selection__box-title {
    font-size: 1.1rem;
    opacity: .8;
    margin-bottom: .1rem;
  }

  .dependency-selection__select {
    width: 100%;
    color: $text;

    .select__control {
      background-color: rgba(255, 255, 255, 0.42);
      border: none;

      &--is-focused,
      &--menu-is-open {
        border: none;
        box-shadow: none;
      }
    }


    .select__single-value,
    .select__placeholder {
      color: $white;
      font-size: 1.5rem;
    }
  }
}
