.contact-box {
  padding-bottom: 2rem;
  text-align: center;

  .contact-box__company-name {
    color: $orange;
    font-size: 2rem;

    @include breakpoint($lt-md) {
      font-size: 1.8rem;
    }
  }

  .contact-box__address,
  .contact-box__phone {
    font-size: 1.8rem;

    @include breakpoint($lt-md) {
      font-size: 1.4rem;
    }

    span {
      color: $orange;
    }
  }
}
