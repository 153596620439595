.complex-selection {
  margin-bottom: 5rem;

  .complex-selection__container {
    background-color: $orange;
    padding: 3.5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .complex-selection__select {
    width: 300px;

    @include breakpoint($lt-md) {
      min-width: 100%;
      width: 100%;
    }

    .select__control {
      background-color: rgba(255, 255, 255, 0.42);
      border: none;

      &--is-focused,
      &--menu-is-open {
        border: none;
        box-shadow: none;
      }
    }

    .select__single-value,
    .select__placeholder {
      color: $white;
      font-size: 1.5rem;
    }
  }

  .complex-selection__info {
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    margin-top: 1rem;
    color: $white;
    font-size: 1.6rem;

    @include breakpoint($lt-md) {
      font-size: 1.3rem;
      p {
        text-align: center;
      }
    }

    p {
      padding: 0 .7rem;
      margin-bottom: 0;
    }
  }
}


