.faq-list {
  .faq-list__box {
    margin-bottom: 2rem;
    background-color: $white;
    padding: 2.5rem 0;
  }

  .faq-list__empty {
    padding: 1rem 5rem;
    text-align: center;

    p {
      font-size: 1.6rem;
    }

    a {
      color: $text-gray;
      font-size: 1.8rem;

      &:hover {
        color: $orange;
      }
    }
  }
}

.faq-list__item {
  padding: 0 12rem !important;

  @include breakpoint($lt-lg) {
    padding: 0 7rem !important;
  }

  @include breakpoint($lt-md) {
    padding: 0 2rem !important;
  }

  .faq-list__item-box {
    border-bottom: 1px solid $text-gray;
    padding-bottom: .8rem;
    margin-bottom: 1.2rem;
  }

  .faq-list__item-box-skeleton {
    padding-bottom: .8rem;
    margin-bottom: 1.2rem;
  }

  .faq-list__item-question {
    text-transform: uppercase;
    font-size: 2rem;
    position: relative;

    @include breakpoint($lt-md) {
      font-size: 1.6rem;
    }

    &:hover {
      cursor: pointer;
      color: $orange;
    }

    &:after {
      content: '';
      background-image: url("/static/icons/arrow.svg");
      width: 12px;
      height: 11px;
      display: inline-block;
      position: absolute;
      right: 0;
      top: calc(50% - 6px);
    }
  }

  .faq-list__item-answer {
    margin-top: .5rem;
    font-size: 1.7rem;

    @include breakpoint($lt-md) {
      font-size: 1.4rem;
    }

    a {
      color: $orange;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &:last-child {
    .faq-list__item-box {
      margin-bottom: 0;
    }
  }

  &.is-open {
    .faq-list__item-question {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
}
