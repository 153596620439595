.services-group-item {
  margin-bottom: 3rem;

  .services-group-item__box {
    background-color: $white;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .services-group-item__img-box {
    margin-bottom: 1.5rem;
  }

  .services-group-item__img {
    width: 84px;
    height: 84px;
  }

  .services-group-item__title {
    font-size: 1.6rem;
    margin-bottom: 3rem;
  }

  .services-group-item__link {
    border: 2px solid $orange;
    color: $orange;
    text-align: center;
    text-transform: uppercase;
    display: block;
    padding: .7rem;
    width: 100%;

    &:hover {
      color: $white;
      background-color: $orange;
    }
  }
}
