.modal-form__container {
  position: relative;

  .modal-form {
    .modal-form__footer {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .modal-form__agree {
      font-size: 1.45rem;

      label {
        padding-left: .5rem;
      }
    }

    .modal-form__help-err {
      font-size: 1.45rem;
      color: $red;
    }

    textarea {
      min-height: 100px;
      max-height: 250px;
    }
  }

  .modal-form__submit-box {
    margin-top: 1rem;
    text-align: center;

    .btn {
      padding: .7rem 4rem;
    }
  }

  &--hidden {
    .modal-form {
      display: none;
    }
  }

  .modal-form__done-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-form__error-icon {
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("/static/icons/close.svg");
    margin-bottom: 2rem;
  }

  .modal-form__ok-icon {
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("/static/icons/ok.svg");
    margin-bottom: 2rem;
  }

  .modal-form__done-title {
    text-transform: uppercase;
    font-size: 2.2rem;
    margin-bottom: 3rem;
    text-align: center;
  }

  .modal-form__done-description {
    //text-transform: uppercase;
    font-size: 1.6rem;
    margin-bottom: 3rem;
  }
}
