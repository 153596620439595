.profile-invoice {
  .profile-invoice__box {
    border: 2px solid $border;
    border-bottom: none;
    padding: 1.5rem;
  }

  .profile-invoice__title-box {
    margin-bottom: 2rem;
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
  }

  .profile-invoice__title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 0;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .profile-invoice__pdf-link {
    color: $orange;

    &:hover {
      text-decoration: underline;
    }
  }


  .profile-invoice__line {
    margin-bottom: 1.5rem;
  }

  .profile-invoice__line-box {
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;

    &--total {
      font-size: 2.6rem;
      font-weight: bold;
    }
  }

  .profile-invoice__line-price {
    font-weight: bold;
  }

  .profile-invoice__line-help {
    text-align: right;
  }

  .profile-invoice__delimiter-box {
    padding: 1.3rem 2.5rem;
    position: relative;
    margin: -2px 0;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 1.3rem;
      height: 2.6rem;
      border-radius: 0 2.6rem 2.6rem 0;
      border-top: 2px solid $border;
      border-right: 2px solid $border;
      border-bottom: 2px solid $border;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 1.3rem;
      height: 2.6rem;
      border-radius: 2.6rem 0 0 2.6rem;
      border-top: 2px solid $border;
      border-left: 2px solid $border;
      border-bottom: 2px solid $border;
    }
  }

  .profile-invoice__delimiter {
    position: relative;
    height: 1px;
    border-bottom: 2px dashed $border;

    .profile-invoice__delimiter-date {
      color: $border;
      position: absolute;
      width: 50%;
      text-align: center;
      background-color: $white;
      right: 25%;
      height: 2rem;
      top: -.9rem;
    }
  }

  .profile-invoice__pay-box {
    border: 2px solid $border;
    border-top: none;
    padding: 1.5rem;
  }

  .profile-invoice__pay-btn {
    display: block;
    border: none;
    outline: none;
    background-color: $orange;
    color: $white;
    width: 100%;
    padding: 1.2rem 0;
    text-align: center;
    font-size: 1.6rem;

    &:hover {
      background-color: $orange-hover;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(241,95,33,.25);
    }

    &:disabled {
      background-color: $orange;
      opacity: .7;
    }
  }

  .profile-invoice__box-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 300px;

    .profile-invoice__box-empty-icon {
      width: 74px;
      height: 74px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("/static/icons/invoice-ok.svg");
      margin-bottom: 1rem;
    }

    .profile-invoice__box-empty-text {
      color: $text-gray-little;
      font-size: 2rem;
      text-align: center;
    }
  }

  .profile-invoice-skeleton__title {
    width: 40%;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  .profile-invoice-skeleton__line {
    margin-bottom: 2rem;
  }

  .profile-invoice-skeleton__line-big {
    margin-bottom: 1rem;
    margin-top: 2rem;
    height: 27px;
  }

  .profile-invoice-skeleton__pay {
    height: 27px;
  }

  .profile-invoice__link-box {
    padding-top: 2rem;
    padding-bottom: .5rem;
    text-align: center;

    a {
      color: $orange;
      font-size: 1.6rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
