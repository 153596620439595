.nav-bar {
  z-index: 10;

  .nav-bar__container {
    display: flex;
    justify-content: space-between;

    &.hidden {
      visibility: hidden;
    }

    &.white {
      .nav-bar__link {
        color: $white;
        border-right: 1px solid $white;

        &:hover {
          color: $orange
        }

        &.active {
          color: $orange
        }
      }

      .nav-bar__contact-phone {
        color: $white;

        &:before {
          background-image: url("/static/icons/call-white.svg");
        }
      }

      .nav-bar__hamburger-line {
        background-color: $white;
      }
    }
  }

  .nav-bar__logo {
    img {
      width: 8vw;
      height: 8vw;

      max-width: 127px;
      max-height: 127px;

      @include breakpoint($lt-md) {
        width: 12vw;
        height: 12vw;
      }

      @include breakpoint($lt-sm) {
        width: 16vw;
        height: 16vw;
      }

      &.home-logo {
        display: none !important;
      }
    }
  }

  .nav-bar__btn-box {
    display: flex;
    flex-direction: column;
    justify-content: center;

  }

  .nav-bar__box {
    margin-top: 2rem;

    @include breakpoint($lt-md) {
      margin-top: 3rem;
    }
  }

  .nav-bar__contact {
    display: flex;
    justify-content: center;
  }

  .nav-bar__contact-phone {
    position: relative;
    padding-left: 1.5vw;
    letter-spacing: 0.5px;
    color: $text-gray;

    @include breakpoint($lt-xl) {
      padding-left: 1.8vw;
    }

    &:before {
      display: block;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
      background-image: url("/static/icons/call-gray.svg");
      width: 1vw;
      height: 1vw;
      left: 0;

      @include breakpoint($lt-xl) {
        width: 1.4vw;
        height: 1.4vw;
      }

      @include breakpoint($lt-md) {
        display: none;
      }
    }
  }

  .nav-bar__ul {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .nav-bar__item {
    &:last-child {
      .nav-bar__link {
        border-right: none;
      }
    }
  }

  .nav-bar__link {
    display: block;
    padding: .1vh 0.5vw 0 0.5vw;
    letter-spacing: 1px;
    color: $text-gray;
    border-right: 1px solid $text-gray;
    font-size: 1.1rem;

    &:hover {
      text-decoration: none;
      color: $orange
    }

    &.active {
      color: $orange
    }

    @include breakpoint($lt-lg) {
      font-size: 1rem;
    }

    @include breakpoint($lt-xl) {
      font-size: 1rem;
      padding: .1vh 0.4vw 0 0.4vw;
    }

    @include breakpoint($lt-lg) {
      font-size: .8rem;
    }
    @include breakpoint($lt-md) {
      font-size: .9rem;
    }
  }

  &--with-bg {
    background-image: url("/static/images/mainSection.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  &.nav-bar--with-bg {
    .nav-bar__logo {
      img {
        &.default-logo {
          display: none !important;
        }

        &.home-logo {
          display: block !important;
        }
      }
    }
  }
}

.nav-bar__hamburger {
  width: 27px;
  height: 27px;
  cursor: pointer;

  .nav-bar__hamburger-line {
    width: 100%;
    height: 5px;
    background-color: $text-gray;
    margin-bottom: 5px;
  }
}

.nav-bar__hamburger-close {
  width: 27px;
  height: 27px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/static/icons/close-white.svg");
}

.nav-bar__mobile-overlay {
  z-index: 100;
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(174, 174, 174, 0.9);

  &.open {
    display:block;
  }

  &.white {
    .nav-bar__contact-phone {
      color: $white;
    }
  }
}

.nav-bar__container-mobile {
  padding-top: 2rem;

  .nav-bar__ul {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .nav-bar__item {
    &:last-child {
      .nav-bar__link {
        border-right: none;
      }
    }
  }

  .nav-bar__link {
    padding: .5rem 0;
    margin: .5rem 0;
    letter-spacing: 1px;
    color: $white;
    border-right: none;
    font-size: 2rem;

    &:hover {
      text-decoration: none;
      color: $orange
    }

    &.active {
      color: $orange
    }
  }
}

.nav-bar__menu {
  display: flex;
  align-items: center;
  margin-top: 1rem
}

.nav-bar__desktop {
  @include breakpoint($lt-md) {
    display: none;
  }
}

.nav-bar__mobile {
  @include breakpoint($gt-sm) {
    display: none;
  }
}

.nav-bar-mobile-app__btn {
  cursor: pointer;
}

.nav-bar-mobile-app__modal {
  .nav-bar-mobile-app__modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-bar-mobile-app__modal-text {
    margin-top: 2rem;
    text-align: center;
    padding: 0 4rem;

    @include breakpoint($lt-md) {
      padding: 0;
    }
  }
}

.home-header {
  height: 36vw;
  padding-top: 12vw;
  margin-bottom: -3rem;

  @include breakpoint($lt-md) {
    height: 80vw;
    padding-top: 30vw;
    margin-bottom: -2rem;
  }

  .home-header__content {
    color: $white;
    text-align: center;
    text-shadow: 0 0 5px rgba(6, 17, 29, 0.7);
    text-transform: uppercase;

    h1 {
      font-weight: 600;
      font-size: 7rem;

      @include breakpoint($lt-md) {
        font-size: 3.6rem;
      }
    }

    h2 {
      font-size: 2.3rem;
      letter-spacing: 0.3rem;

      @include breakpoint($lt-md) {
        font-size: 1.8rem;
        letter-spacing: 0.1rem;
      }
    }
  }
}
