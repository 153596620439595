.pagination-box {

  @include breakpoint($lt-md) {
    display: flex;
    justify-content: center;
  }

  .page-link {
    background: none;
  }

  .page-item {
    border: 1px solid $text-gray;
    margin-left: -1px;

    .page-link {
      min-width: 24px;
      text-align: center;
      border: none;
      border-radius: 0;
      font-size: 1.4rem;
      color: $text-gray;
      padding: .5rem .5rem .4rem .5rem;

      span {
        font-size: 1.4rem;
      }

      &:focus {
        box-shadow: none;
      }
    }

    &.active {
      .page-link {
        color: $white;
        background-color: $text-gray;
      }
    }

    &.disabled {

      .page-link {
        background: none;
      }
    }
  }

  .page-item__prev {
    border-radius: 0;
    margin-right: 2rem;
  }

  .page-item__next {
    border-radius: 0;
    margin-left: 2rem;
  }
}
