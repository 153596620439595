.invoices-list {
  .invoices-list__box {
    background-color: $white;
  }
}

.invoices-list__item {
  border-bottom: 1px solid $border;

  &:last-child {
    border-bottom: none;
  }
}

.invoices-list__item-box {
  padding: 2rem 4rem;

  @include breakpoint($lt-md) {
    padding: 2rem;
  }
}

.invoices-list__item-date-box {
  display: flex;
  align-items: center;
}

.invoices-list__item-date {
  font-size: 2rem;
  font-weight: bold;

  @include breakpoint($lt-md) {
    font-size: 2.4rem;
    margin-bottom: 1rem;
  }

  &:first-letter {
    text-transform: uppercase;
  }
}

.invoices-list__item-line {
  font-size: 1.6rem;
  display: flex;
  justify-content: space-between;

  &--total {
    font-size: 2.6rem;
    font-weight: bold;

    @include breakpoint($lt-md) {
      font-size: 2rem;
    }
  }

  +.invoices-list__item-line {
    margin-top: 1.5rem;
  }
}

.invoices-list__item-help-line {
  text-align: right;
  +.invoices-list__item-line {
    margin-top: 1.5rem;
  }
}

.invoices-list__delimiter {
  height: 1px;
  border-bottom: 2px dashed $border;
  margin: .7rem 0;
}

.invoices-list__status-box {
  font-size: 1.8rem;
  display: flex;
  justify-content: space-between;
  margin-top: 1.3rem;

  .invoices-list__status {
    color: $text-gray;
  }

  .invoices-list__pdf-link {
    color: $orange;

    &:hover {
      text-decoration: underline;
    }
  }
}

.invoices-list__empty {
  padding: 10rem 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .invoices-list__empty-icon {
    width: 85px;
    height: 65px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("/static/icons/credit-card.svg");
    margin-bottom: 1rem;
  }

  .invoices-list__empty-text {
    color: $text-gray-little;
    font-size: 2rem;
    text-align: center;
  }
}
