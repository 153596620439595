.request-call__btn {
  color: $orange;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.request-call__modal-body {
  padding: 0 8rem;

  @include breakpoint($lt-xl) {
    padding: 0 5rem;
  }

  @include breakpoint($lt-xl) {
    padding: 0;
  }
}
